ion-modal#card-modal {
    --min-width: 250px;
    --max-width: 300px;
    padding-top: 10vh;
    padding-bottom: 20vh;
    --border-radius: 6px;
    height: 100vh;
}



.textarea-item {
    border-radius: 8px;
    
  }
  
  .textarea-label {
    color: #3880ff;
    
  }
  
  .textarea-input {
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    background-color: #ffffff;
    color: #333;
    min-height: 150px; /* Ensures the textarea doesn't shrink too much */
  }
  
  .character-counter {
    margin-top: 4px;
    font-size: 12px;
    text-align: right;
    transition: color 0.3s ease;
  }
  
  .error-text {
    color: red;
  }
  
  .progress-bar-container {
    margin-top: 6px;
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .progress-bar {
    height: 100%;
    transition: width 0.3s ease;
  }

  .map-viewer-container {
    width: 100%;
  }
  .map-viewer {
    height: 50vh;
  }
  