.positive-sentiment-color {
    color: green;
}

.negative-sentiment-color {
    color: red;
}

.neutral-sentiment-color {
    color: yellow;
}

.custom-modal {
    --background: rgba(0, 0, 0, 0.2);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    width: 350px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card-header {
    justify-content: space-between;
    padding: 0px 5px;
}

.chip {
    font-size: 10px;
    border-radius: 5px;
    height: 20px;
    padding: 0px 5px;
}

pre {
    font-family: sans-serif;
    font-size: 12px;
}

.action-button {
    font-size: 13px;
    padding: 0px;
}

ion-col {
    padding: 0px;
    margin: 0px;
}

ion-row {
    margin: 5px 0px;
}

.text-area { 
    width: 98%;
    max-width: 98%;
    min-width: 50%;
    height: 180px;
    max-height: 200px;
    min-height: 100px;

    margin: auto;
    margin-top: 10px;

    border: 1px solid gray;
    border-radius: 10px;
    padding: 5px 10px;
  }
  