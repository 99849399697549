.errorMsg{
    color: red;
    font-size: small;
    margin-left: 1.5em;
}

.password-container {
    margin: 0px;
    width: 100%;
    padding: 0px 20px 0px 0px;
    display: flex; 
    align-items: center;
    justify-content: space-between;
}