.menuLink {
    text-decoration: none;
}

/* .floating-button:
This class is used to create a floating button that stays fixed at the bottom center of the screen.
It is helpful when you want to place an action button, such as a "Save" or "Submit" button, that remains visible 
to the user even when they scroll through a page. */
.floating-button {
    position: fixed;  
    bottom: 20px; 
    left: 50%; 
    transform: translateX(-50%);  
    width: 90%;  
    z-index: 10;  
    --ion-button-padding: 10px 0;  
  }
  