.searchBoxContainer{
    min-width: 100%;
    padding: 5px;
}
#plotSearchBox{
    min-width: 95%;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    border-width: 0px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 2px;

}
#plotSearchBox:focus{
    outline: none !important;
   
}