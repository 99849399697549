.custom-card {
    margin: 10px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .scheme-modal {
    --background: #ffffff;
    --border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  .scheme-modal ion-card {
    /* margin: 16px; */
    border-radius: 10px;
  }
.modal-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  
  .modal-field-title {
    font-weight: bold;
    margin-bottom: 4px;
    color: var(--ion-color-dark);
    font-size: 0.95em;
  }
  
  .modal-field-value {
    color: var(--ion-color-medium);
    white-space: pre-line;
    font-size: 0.9em;
  }
  
  