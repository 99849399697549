.loader-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
      }
      
      .loader-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      }
      
      .spinner {
        font-size: 50px;
        color: #126dd5;
        animation: spin 1s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      
      .loader-message {
        margin-top: 10px;
        color: white;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
      
      /* Responsive adjustments */
      @media (max-width: 768px) {
        .spinner {
          font-size: 40px;
        }
        .loader-message {
          font-size: 16px;
        }
      }
      
      @media (max-width: 480px) {
        .spinner {
          font-size: 30px;
        }
        .loader-message {
          font-size: 14px;
        }
      }
      