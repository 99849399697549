#google-maps-autocomplete-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
}

#google-maps-autocomplete-input {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 80vw;
  height: 40px;
  padding: 0 12px;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
}