.logo {
    height: 6em;
    float: right;
    width: 4em;
}

.title {
    font-size: 3em;
    color: black;
}

.sub-title {
    text-align: center;
}

.login-card {
    margin-top: 35%;
}

.login-content {
    margin-top: 3em;
}

.button-panel {
    margin-top: 2em;
}