.searchBarContainer{
    display: flex;
    width: 100vw;
}
.sbContainer{
    min-width: 80vw;
    display: flex;
    align-items: center;
}
.searchBarContainer .iconContainer{
    width: 15vw;
    display: flex;
    justify-content: center;
    align-items:center; 
}
  
@media screen and (min-width:680px) {
    .searchBarContainer{
        display: flex;
        width: 70vw;
    }
    .sbContainer{
        margin-left: 1vw;
        min-width: 64vw;
    
    }
    .searchBarContainer div{
        margin-right: 1vw;
        width: 15vw;
        display: flex;
        justify-content: center;
        align-items: center;
     
    }
  
}