.container {
  padding: 10px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-area { 
  width: 95%;
  margin-left: 2.5%; 
  max-width: 95%;
  min-width: 50%;
  height: 180px;
  max-height: 200px;
  min-height: 100px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 5px 10px;
}

.ion-toolbar {
  padding: 0px 5px;
  display: flex;
  justify-content: center;
}

.stage-button {
  width: 48%;
  display: inline-block;
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
}