.card-image {
    width: 100%;
    height: 30vh;
}

ion-card#card-style {
    --min-width: 150px;
    --max-width: 200px;
    /* height: 50vh; */
    --border-radius: 6px;
}

ion-card-header#card-header {
    --min-width: 150px;
    --max-width: 200px;
    text-align: start;
    --border-radius: 6px;
}

ion-card-title#card-text {
    font-size: 3vh;
    font-weight: 700;
    margin-top: .7vh;
    margin-bottom: 1.5vh;
    text-decoration: underline;

}

ion-card-subtitle#card-subttitle {
    font-size: 1.7vh;
    margin: .5vh;
    font-weight: 500;
    text-transform: lowercase;
}