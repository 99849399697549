
.vegetation-reading-container { 
    height: 60vh;
	margin-bottom: 40px;
    padding: 0px;
	padding-top: 5px;
}

.vegetation-label { 
	margin: 20px 0px;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.vegetation-label label {
	font-size: 20px;
}

.reading-container {
	display: block;
}

.reading-field-container {
	padding-bottom: 10px;
	border-bottom: 0.5px solid silver;
}

.reading-field {
	margin: 10px 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 5px;
}

.reading-thumbnails-container {
	display: flex;
}

.reading-thumbnails-container > img {
	width: 50px;
}

.column-label {
	font-size: 14px;
}

.description-modal {
	padding: 20px;
}

.description-modal-header {
	font-size: 20px;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid gray;
}

.description-container {
	padding: 10px 0px;
}

.description-close-button-container {
	display: flex;
	justify-content: center;
	margin: 20px;
}

.date-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}